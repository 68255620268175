import React, {useEffect} from 'react'
import AOS from 'aos';
import imagesLoaded from 'imagesloaded';
import Isotope from 'isotope-layout';
import { Swiper, SwiperSlide } from 'swiper/react';
import {Link, useLocation} from 'react-router-dom'
import GLightbox from 'glightbox'; // Importa GLightbox
import { BsList } from "react-icons/bs";
import { IoMdArrowUp } from "react-icons/io";

import 'glightbox/dist/css/glightbox.css'; // Asegúrate de importar el CSS
export default function About() {

  const { pathname } = useLocation();

  useEffect(() => {
    const toggleScrolled = () => {
      const selectBody = document.querySelector("body");
      const selectHeader = document.querySelector("#header");
      if (!selectHeader) return;
      if (
        !selectHeader.classList.contains("scroll-up-sticky") &&
        !selectHeader.classList.contains("sticky-top") &&
        !selectHeader.classList.contains("fixed-top")
      )
        return;
      window.scrollY > 100
        ? selectBody.classList.add("scrolled")
        : selectBody.classList.remove("scrolled");
    };

    // Añadir eventos de scroll
    document.addEventListener("scroll", toggleScrolled);
    toggleScrolled(); // Llamar inmediatamente para verificar si la página ya está scrolleada.

    const mobileNavToggleBtn = document.querySelector(".mobile-nav-toggle");

    const mobileNavToogle = () => {
      document.querySelector("div").classList.toggle("mobile-nav-active"); // Cambiar 'div' por un selector más específico
      mobileNavToggleBtn.classList.toggle("bi-list");
      mobileNavToggleBtn.classList.toggle("bi-x");
    };

    if (mobileNavToggleBtn) {
      mobileNavToggleBtn.addEventListener("click", mobileNavToogle);
    }

    document.querySelectorAll("#navmenu a").forEach((navmenu) => {
      navmenu.addEventListener("click", () => {
        if (document.querySelector(".mobile-nav-active")) {
          mobileNavToogle();
        }
      });
    });

    document
      .querySelectorAll(".navmenu .toggle-dropdown")
      .forEach((navmenu) => {
        navmenu.addEventListener("click", function (e) {
          e.preventDefault();
          this.parentNode.classList.toggle("active");
          this.parentNode.nextElementSibling.classList.toggle(
            "dropdown-active"
          );
          e.stopImmediatePropagation();
        });
      });

    // Eliminar el preloader inmediatamente si existe
    const preloader = document.querySelector("#preloader");
    if (preloader) {
      preloader.remove();
    }

    let scrollTop = document.querySelector(".scroll-top");
    if (scrollTop) {
      const toggleScrollTop = () => {
        window.scrollY > 100
          ? scrollTop.classList.add("active")
          : scrollTop.classList.remove("active");
      };

      scrollTop.addEventListener("click", (e) => {
        e.preventDefault();
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });

      document.addEventListener("scroll", toggleScrollTop);
      toggleScrollTop(); // Verificar estado inicial del scroll-top
    }

    // Inicialización de AOS (Animaciones)
    const aosInit = () => {
      if (typeof AOS !== "undefined") {
        AOS.init({
          duration: 600,
          easing: "ease-in-out",
          once: true,
          mirror: false,
        });
        AOS.refresh();
      }
    };
    aosInit(); // Llamar directamente para inicializar sin depender de 'load'

    // Inicialización de Swiper
    const initSwiper = () => {
      document
        .querySelectorAll(".init-swiper")
        .forEach(function (swiperElement) {
          const configElement = swiperElement.querySelector(".swiper-config");
          if (configElement) {
            let config = JSON.parse(configElement.innerHTML.trim());
            if (!swiperElement.classList.contains("swiper-tab")) {
              new Swiper(swiperElement, config);
            }
          }
        });
    };
    initSwiper(); // Llamar directamente sin depender de 'load'

    // Limpieza al desmontar el componente
    return () => {
      document.removeEventListener("scroll", toggleScrolled);
      if (mobileNavToggleBtn) {
        mobileNavToggleBtn.removeEventListener("click", mobileNavToogle);
      }
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
    return (
        <div class="about-page">

<header id="header" class="header d-flex align-items-center fixed-top">
          <div class="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
      
            <Link href="/" class="logo d-flex align-items-center">
             <img src={require("../../assets/img/Home/logo.jpg")} alt="" />
            </Link>
      
            <nav id="navmenu" class="navmenu">
              <ul>
                <li><Link to="/" >Home</Link></li>
                <li><Link to="/about" class="active">About</Link></li>
               {/*  <li><Link to="/services">Services</Link></li>*/}
             
                <li><Link to="/contact">Contact</Link></li> 
              </ul>
              <i class="mobile-nav-toggle d-xl-none bi bi-list"><BsList/></i>
            </nav>
      
          </div>
        </header>
      
        <main class="main">
      
          <div class="page-title dark-background" >
            <div class="container position-relative">
              <h1>About</h1>
              <nav class="breadcrumbs">
                <ol>
                  <li><Link to="/">Home</Link></li>
                  <li class="current">About</li>
                </ol>
              </nav>
            </div>
          </div>
          <section id="alt-services-2" class="alt-services-2 section">
  <div class="container">
    <div class="row justify-content-around gy-4">
      <div class="col-lg-6 d-flex flex-column justify-content-center order-2 order-lg-1" data-aos="fade-up" data-aos-delay="100">
        <h3>About Us</h3>
        <p>
          We are a company dedicated to providing high-quality services that enhance your travel experience. From personalized transport options to unmatched comfort, we ensure that your journey through Baja Blue Transportation is nothing less than exceptional.
        </p>

        <div class="row">
          <div class="col-lg-6 icon-box d-flex">
            <i class="bi bi-easel flex-shrink-0"></i>
            <div>
              <h4>Our Mission</h4>
              <p>We strive to provide top-notch, reliable, and comfortable transportation services that cater to all your travel needs in Baja Blue Transportation.</p>
            </div>
          </div>

          <div class="col-lg-6 icon-box d-flex">
            <i class="bi bi-patch-check flex-shrink-0"></i>
            <div>
              <h4>Our Values</h4>
              <p>We are committed to delivering excellence, transparency, and trust in every ride. Customer satisfaction is our top priority.</p>
            </div>
          </div>

          <div class="col-lg-6 icon-box d-flex">
            <i class="bi bi-brightness-high flex-shrink-0"></i>
            <div>
              <h4>Premium Experience</h4>
              <p>Experience luxury at its finest with our fleet of well-maintained vehicles, offering comfort and safety at every turn.</p>
            </div>
          </div>

          <div class="col-lg-6 icon-box d-flex">
            <i class="bi bi-brightness-high flex-shrink-0"></i>
            <div>
              <h4>Customer Focus</h4>
              <p>Our clients are at the heart of everything we do. We go above and beyond to ensure your satisfaction and comfort throughout your journey.</p>
            </div>
          </div>
        </div>

      </div>

      <div class="features-image col-lg-5 order-1 order-lg-2" data-aos="fade-up" data-aos-delay="200">
        <img src={require("../../assets/img/Camionetas/auto-1.jpg")} alt=""/>
      </div>

    </div>
  </div>
</section>

<section id="about" class="about section">
  <div class="container">
    <div class="row position-relative">
      <div class="col-lg-7 about-img" data-aos="zoom-out" data-aos-delay="200">
        <img src={require("../../assets/img/Camionetas/camioneta-1.jpg")} alt=""/>
      </div>

      <div class="col-lg-7" data-aos="fade-up" data-aos-delay="100">
        <h2 class="inner-title">Our Journey to Excellence</h2>
        <div class="our-story">
          <h4>Since 1988</h4>
          <h3>Our Story</h3>
          <p>
            From humble beginnings in 1988, we’ve dedicated ourselves to providing high-quality, reliable transportation services. What started as a small company has grown into a trusted leader in Baja Blue Transportation. With years of experience, we understand the needs of travelers, and our commitment to comfort, safety, and customer satisfaction has made us the go-to choice for anyone visiting this beautiful region.
          </p>
          <ul>
            <li><i class="bi bi-check-circle"></i> <span>Committed to exceptional customer service</span></li>
            <li><i class="bi bi-check-circle"></i> <span>Reliable, on-time transportation every time</span></li>
            <li><i class="bi bi-check-circle"></i> <span>Luxury vehicles to ensure your comfort</span></li>
          </ul>
          <p>
            Our goal is to make every journey a seamless experience. From airport pickups to scenic tours, we are here to make your travel through Baja Blue Transportation as smooth and enjoyable as possible.
          </p>

          <div class="watch-video d-flex align-items-center position-relative">
            <i class="bi bi-play-circle"></i>
            <a href="https://www.youtube.com/watch?v=Y7f98aduVJ8" class="glightbox stretched-link">Watch Our Story</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

        
       
      
         
       
      
      
      
        </main>
      
       
      
        <a
        href="#"
        id="scroll-top"
        class="scroll-top d-flex align-items-center justify-content-center"
      >
        <IoMdArrowUp class="icon-arrow-up" />
      </a>
      
      </div>
    )
}