import React, { useEffect } from "react";
import AOS from "aos";
import imagesLoaded from "imagesloaded";
import Isotope from "isotope-layout";
import { Swiper, SwiperSlide } from "swiper/react";
import "aos/dist/aos.css"; // Importa los estilos de AOS
import { Link, useLocation } from "react-router-dom";
import { IoMdArrowUp } from "react-icons/io";
import { IoMdArrowBack } from "react-icons/io";
import { IoArrowForward } from "react-icons/io5";
import { FiZoomIn } from "react-icons/fi";
import { FaClipboardUser } from "react-icons/fa6";
import { FaComment } from "react-icons/fa";
import { LuUserCheck } from "react-icons/lu";
import { RiSecurePaymentFill } from "react-icons/ri";
import { RiGitRepositoryPrivateFill } from "react-icons/ri";
import { TbPigMoney } from "react-icons/tb";
import { BsList } from "react-icons/bs";
import { FaCreditCard } from "react-icons/fa6";
import { FaIdCard } from "react-icons/fa6";
import { IoLogoPaypal } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";
import FacebookOutlinedIcon from "@mui/icons-material/Facebook";
import GLightbox from "glightbox"; // Importa GLightbox
import "glightbox/dist/css/glightbox.css"; // Asegúrate de importar el CSS

export default function HomeComponent() {
  const { pathname } = useLocation();
  
  useEffect(() => {
    const toggleScrolled = () => {
      const selectBody = document.querySelector("body");
      const selectHeader = document.querySelector("#header");
      if (!selectHeader) return;
      if (
        !selectHeader.classList.contains("scroll-up-sticky") &&
        !selectHeader.classList.contains("sticky-top") &&
        !selectHeader.classList.contains("fixed-top")
      )
        return;
      window.scrollY > 100
        ? selectBody.classList.add("scrolled")
        : selectBody.classList.remove("scrolled");
    };
    const glightbox = GLightbox({
      selector: '.glightbox',
    });
    // Añadir eventos de scroll
    document.addEventListener("scroll", toggleScrolled);
    toggleScrolled(); // Llamar inmediatamente para verificar si la página ya está scrolleada.

    const mobileNavToggleBtn = document.querySelector(".mobile-nav-toggle");

    const mobileNavToogle = () => {
      document.querySelector("div").classList.toggle("mobile-nav-active"); // Cambiar 'div' por un selector más específico
      mobileNavToggleBtn.classList.toggle("bi-list");
      mobileNavToggleBtn.classList.toggle("bi-x");
    };

    if (mobileNavToggleBtn) {
      mobileNavToggleBtn.addEventListener("click", mobileNavToogle);
    }

    document.querySelectorAll("#navmenu a").forEach((navmenu) => {
      navmenu.addEventListener("click", () => {
        if (document.querySelector(".mobile-nav-active")) {
          mobileNavToogle();
        }
      });
    });

    document
      .querySelectorAll(".navmenu .toggle-dropdown")
      .forEach((navmenu) => {
        navmenu.addEventListener("click", function (e) {
          e.preventDefault();
          this.parentNode.classList.toggle("active");
          this.parentNode.nextElementSibling.classList.toggle(
            "dropdown-active"
          );
          e.stopImmediatePropagation();
        });
      });

    // Eliminar el preloader inmediatamente si existe
    const preloader = document.querySelector("#preloader");
    if (preloader) {
      preloader.remove();
    }

    let scrollTop = document.querySelector(".scroll-top");
    if (scrollTop) {
      const toggleScrollTop = () => {
        window.scrollY > 100
          ? scrollTop.classList.add("active")
          : scrollTop.classList.remove("active");
      };

      scrollTop.addEventListener("click", (e) => {
        e.preventDefault();
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });

      document.addEventListener("scroll", toggleScrollTop);
      toggleScrollTop(); // Verificar estado inicial del scroll-top
    }

    // Inicialización de AOS (Animaciones)
    const aosInit = () => {
      if (typeof AOS !== "undefined") {
        AOS.init({
          duration: 600,
          easing: "ease-in-out",
          once: true,
          mirror: false,
        });
        AOS.refresh();
      }
    };
    aosInit(); // Llamar directamente para inicializar sin depender de 'load'

    // Inicialización de Swiper
    const initSwiper = () => {
      document
        .querySelectorAll(".init-swiper")
        .forEach(function (swiperElement) {
          const configElement = swiperElement.querySelector(".swiper-config");
          if (configElement) {
            let config = JSON.parse(configElement.innerHTML.trim());
            if (!swiperElement.classList.contains("swiper-tab")) {
              new Swiper(swiperElement, config);
            }
          }
        });
    };
    initSwiper(); // Llamar directamente sin depender de 'load'

    // Limpieza al desmontar el componente
    return () => {
      document.removeEventListener("scroll", toggleScrolled);
      if (mobileNavToggleBtn) {
        mobileNavToggleBtn.removeEventListener("click", mobileNavToogle);
      }
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div class="index-page">
      <header id="header" class="header d-flex align-items-center fixed-top">
        <div class="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
          <Link href="/" class="logo d-flex align-items-center">
            <img src={require("../../assets/img/Home/logo.jpg")} alt="" />
          </Link>

          <nav id="navmenu" class="navmenu">
            <ul>
              <li>
                <Link to="/" class="active">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              {/*  <li><Link to="/services">Services</Link></li>*/}

              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
            <i class="mobile-nav-toggle d-xl-none bi bi-list">
              <BsList />
            </i>
          </nav>
        </div>
      </header>
      <main class="main">
        <section id="hero" class="hero section dark-background">
          <div class="info d-flex align-items-center">
            <div class="container">
              <div
                class="row justify-content-center"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div class="col-lg-6 text-center">
                  <h2>Baja Blue Transportation</h2>
                  <p>
                    The best Transportation Service to and from Cabo San Lucas
                    and Cabo San Lucas Airport. Transportation at the best price
                    with Cabo Shuttle.
                  </p>
                  <a href="#get-started" class="btn-get-started">
                    BOOK NOW
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            id="hero-carousel"
            class="carousel slide"
            data-bs-ride="carousel"
            data-bs-interval="5000"
          >
              <div class="carousel-item active">
              <img src={require("../../assets/img/Camionetas/camioneta-2.jpg")} alt="" />

            </div>
         
            <div class="carousel-item ">
              <img src={require("../../assets/img/Trafies/Trafier-1.jpg")} alt="" />
            </div>
            <div class="carousel-item">
              <img src={require("../../assets/img/Trafies/Trafier-2.jpg")} alt="" />
            </div>
            <div class="carousel-item ">
              <img src={require("../../assets/img/Camionetas/camioneta-1.jpg")} alt="" />
            </div>

            <div class="carousel-item ">
              <img src={require("../../assets/img/Camionetas/auto-1.jpg")} alt="" />
            </div>

            <div class="carousel-item ">
              <img src={require("../../assets/img/Trafies/camionetas-2.jpg")} alt="" />
            </div>
            
            
            
            <a
              class="carousel-control-prev"
              href="#hero-carousel"
              role="button"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon bi bi-chevron-left"
                aria-hidden="true"
              >
                <IoMdArrowBack />
              </span>
            </a>
            <a
              class="carousel-control-next"
              href="#hero-carousel"
              role="button"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon bi bi-chevron-right"
                aria-hidden="true"
              >
                <IoArrowForward />
              </span>
            </a>
          </div>
        </section>

        <section id="services" class="services section light-background">
  <div class="container section-title" data-aos="fade-up">
    <h2>
      Discover Baja Blue Transportation with the service you deserve
    </h2>
    <p>
      We offer exclusive travel experiences in the beautiful destinations of Baja Blue Transportation, Mexico.
    </p>
  </div>

  <div class="container">
    <div class="row gy-4">
      <div
        class="col-lg-4 col-md-6"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <div class="service-item position-relative">
          <div class="icon">
            <i class="fa-solid fa-mountain-city">
              <LuUserCheck />
            </i>
          </div>
          <h3>
            The best guaranteed rates for your travels in B.C.S.
          </h3>
          <p>
            Book with confidence and enjoy unbeatable prices on all your transfers within Baja Blue Transportation. Experience an affordable trip without compromising on quality.
          </p>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        <div class="service-item position-relative">
          <div class="icon">
            <i class="fa-solid fa-arrow-up-from-ground-water">
              <FaClipboardUser />
            </i>
          </div>
          <h3>
            Transportation available 24/7 in Baja Blue Transportation.
          </h3>
          <p>
            Our reliable transportation services cover all destinations in B.C.S., ensuring you have support at any time of the day or night.
          </p>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6"
        data-aos="fade-up"
        data-aos-delay="300"
      >
        <div class="service-item position-relative">
          <div class="icon">
            <i class="fa-solid fa-compass-drafting">
              <FaComment />
            </i>
          </div>
          <h3>
            Safe transfers for a worry-free journey.
          </h3>
          <p>
            Enjoy reliable and safe transfers in Baja Blue Transportation. Your safety and comfort will always be our priority.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>



<section id="alt-services-2" class="alt-services-2 section">
  <div class="container">
    <div class="row justify-content-around gy-4">
      <div
        class="col-lg-6 d-flex flex-column justify-content-center order-2 order-lg-1"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <h3>Transportation in Baja Blue Transportation</h3>
        <h4>
          Enjoy the Best Private Transportation Service in Baja Blue Transportation
        </h4>
        <p>
          Exploring Baja Blue Transportation is an unforgettable experience, and the first step to enjoying it to the fullest is having reliable and comfortable transportation from the airport or any other point. With our private transportation service in Baja Blue Transportation, we guarantee comfort, punctuality, and a hassle-free journey. Our luxury vehicles are available 24 hours a day, ready to take you to your destination safely and efficiently, whether you're visiting La Paz, Cabo San Lucas, Loreto, or other fascinating destinations.
        </p>
      </div>

      <div
        class="features-image col-lg-5 order-1 order-lg-2"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        <img src={require("../../assets/img/Home/duo.jpg")} alt="Private transportation in Baja Blue Transportation" />
      </div>
    </div>
  </div>
</section>



<section id="services" class="services section light-background">
  <div class="container section-title" data-aos="fade-up">
    <h2>Transportation to and from Baja Blue Transportation Airport</h2>
    <p>La Paz, Cabo San Lucas, Loreto and surroundings</p>
  </div>

  <div class="container">
    <div class="row gy-4">
      <div
        class="col-lg-4 col-md-6"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <div class="service-card position-relative">
          <div class="icon">
            <i class="fa-solid fa-mountain-city">
              <RiSecurePaymentFill />
            </i>
          </div>
          <p>Transparency</p>
          <h3>Clear rates and no surprise fees</h3>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6"
        data-aos="fade-up"
        data-aos-delay="300"
      >
        <div class="service-card position-relative">
          <div class="icon">
            <i class="fa-solid fa-compass-drafting">
              <RiGitRepositoryPrivateFill />
            </i>
          </div>
          <p>Popular Transportation Service</p>
          <h3>Private</h3>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6"
        data-aos="fade-up"
        data-aos-delay="300"
      >
        <div class="service-card position-relative">
          <div class="icon">
            <i class="fa-solid fa-compass-drafting">
              <TbPigMoney />
            </i>
          </div>
          <p>Best price</p>
          <h3>$54.24 (one way)</h3>
        </div>
      </div>
    </div>
  </div>
</section>


        {/*   <section id="get-started" class="get-started section">
      
            <div class="container">
      
              <div class="row justify-content-between gy-4">
      
                <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="100">
                  <div class="content">
                    <h3>Minus hic non reiciendis ea possimus at quia.</h3>
                    <p>Rem id rerum. Debitis deserunt quidem delectus expedita ducimus dolor. Aut iusto ipsa. Eos ipsum nobis ipsa soluta itaque perspiciatis fuga ipsum perspiciatis. Eum amet fugiat totam nisi possimus ut delectus dicta.
                    </p>
                    <p>Aliquam velit deserunt autem. Inventore et saepe. Tenetur suscipit eligendi labore culpa eos. Deserunt porro magni qui necessitatibus dolorem at animi cupiditate.</p>
                  </div>
                </div>
      
                <div class="col-lg-5" data-aos="zoom-out" data-aos-delay="200">
                  <form action="forms/quote.php" method="post" class="php-email-form">
                    <h3>Get a quote</h3>
                    <p>Vel nobis odio laboriosam et hic voluptatem. Inventore vitae totam. Rerum repellendus enim linead sero park flows.</p>
                    <div class="row gy-3">
      
                      <div class="col-12">
                        <input type="text" name="name" class="form-control" placeholder="Name" required=""/>
                      </div>
      
                      <div class="col-12 ">
                        <input type="email" class="form-control" name="email" placeholder="Email" required=""/>
                      </div>
      
                      <div class="col-12">
                        <input type="text" class="form-control" name="phone" placeholder="Phone" required=""/>
                      </div>
      
                      <div class="col-12">
                        <textarea class="form-control" name="message" rows="6" placeholder="Message" required=""></textarea>
                      </div>
      
                      <div class="col-12 text-center">
                        <div class="loading">Loading</div>
                        <div class="error-message"></div>
                        <div class="sent-message">Your quote request has been sent successfully. Thank you!</div>
      
                        <button type="submit">Get a quote</button>
                      </div>
      
                    </div>
                  </form>
                </div>
      
              </div>
      
            </div>
      
          </section> */}

        {/* <section id="alt-services" class="alt-services section">
          <div class="container">
            <div class="row justify-content-around gy-4">
              <div
                class="features-image col-lg-6"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <img
                  src={require("../../assets/img/alt-services.jpg")}
                  alt=""
                />
              </div>

              <div
                class="col-lg-5 d-flex flex-column justify-content-center"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h3>Transportation in Cabo San Lucas and Los Cabos.</h3>
                <h4>
                Enjoy the Best Private Transportation Service in Los Cabos & Cabo San Lucas
                </h4>
                <p>
                  Esse voluptas cumque vel exercitationem. Reiciendis est hic
                  accusamus. Non ipsam et sed minima temporibus laudantium.
                  Soluta voluptate sed facere corporis dolores excepturi
                </p>

                <div
                  class="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <i class="bi bi-easel flex-shrink-0"></i>
                  <div>
                    <h4>
                      <a href="" class="stretched-link">
                        Lorem Ipsum
                      </a>
                    </h4>
                    <p>
                      Voluptatum deleniti atque corrupti quos dolores et quas
                      molestias excepturi sint occaecati cupiditate non
                      provident
                    </p>
                  </div>
                </div>

                <div
                  class="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <i class="bi bi-patch-check flex-shrink-0"></i>
                  <div>
                    <h4>
                      <a href="" class="stretched-link">
                        Nemo Enim
                      </a>
                    </h4>
                    <p>
                      At vero eos et accusamus et iusto odio dignissimos ducimus
                      qui blanditiis praesentium voluptatum deleniti atque
                    </p>
                  </div>
                </div>

                <div
                  class="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  <i class="bi bi-brightness-high flex-shrink-0"></i>
                  <div>
                    <h4>
                      <a href="" class="stretched-link">
                        Dine Pad
                      </a>
                    </h4>
                    <p>
                      Explicabo est voluptatum asperiores consequatur magnam. Et
                      veritatis odit. Sunt aut deserunt minus aut eligendi omnis
                    </p>
                  </div>
                </div>

                <div
                  class="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="600"
                >
                  <i class="bi bi-brightness-high flex-shrink-0"></i>
                  <div>
                    <h4>
                      <a href="" class="stretched-link">
                        Tride clov
                      </a>
                    </h4>
                    <p>
                      Est voluptatem labore deleniti quis a delectus et. Saepe
                      dolorem libero sit non aspernatur odit amet. Et eligendi
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/*  <section id="alt-services" class="alt-services section">
      
            <div class="container">
      
              <div class="row justify-content-around gy-4">
                <div class="features-image col-lg-6" data-aos="fade-up" data-aos-delay="100"><img src="assets/img/alt-services.jpg" alt=""/></div>
      
                <div class="col-lg-5 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                  <h3>Enim quis est voluptatibus aliquid consequatur fugiat</h3>
                  <p>Esse voluptas cumque vel exercitationem. Reiciendis est hic accusamus. Non ipsam et sed minima temporibus laudantium. Soluta voluptate sed facere corporis dolores excepturi</p>
      
                  <div class="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="300">
                    <i class="bi bi-easel flex-shrink-0"></i>
                    <div>
                      <h4><a href="" class="stretched-link">Lorem Ipsum</a></h4>
                      <p>Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
                    </div>
                  </div>
      
                  <div class="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="400">
                    <i class="bi bi-patch-check flex-shrink-0"></i>
                    <div>
                      <h4><a href="" class="stretched-link">Nemo Enim</a></h4>
                      <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque</p>
                    </div>
                  </div>
      
                  <div class="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="500">
                    <i class="bi bi-brightness-high flex-shrink-0"></i>
                    <div>
                      <h4><a href="" class="stretched-link">Dine Pad</a></h4>
                      <p>Explicabo est voluptatum asperiores consequatur magnam. Et veritatis odit. Sunt aut deserunt minus aut eligendi omnis</p>
                    </div>
                  </div>
      
                  <div class="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="600">
                    <i class="bi bi-brightness-high flex-shrink-0"></i>
                    <div>
                      <h4><a href="" class="stretched-link">Tride clov</a></h4>
                      <p>Est voluptatem labore deleniti quis a delectus et. Saepe dolorem libero sit non aspernatur odit amet. Et eligendi</p>
                    </div>
                  </div>
      
                </div>
              </div>
      
            </div>
      
          </section> */}
      <section id="constructions" class="constructions section">
  <div class="container section-title" data-aos="fade-up">
    <h2>The Ideal Cabo Airport Shuttle Service for Every Occasion</h2>
    <p>
      Whether you're traveling from the airport to your hotel, from one hotel to another, or perhaps heading from your hotel to the airport, we offer all possible options to get you to your destination quickly.
    </p>
  </div>

  <div class="container">
    <div class="row gy-4">
      <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
        <div class="card-item">
          <div class="row">
            <div class="col-xl-5">
              <div class="card-bg">
                <img
                  src="https://cabo-airport-shuttle.com/assets/img/destinations/cabo-san-lucas.webp"
                  alt="Cabo San Lucas"
                />
              </div>
            </div>
            <div class="col-xl-7 d-flex align-items-center">
              <div class="card-body">
                <h4 class="card-title">Cabo San Lucas</h4>
                <p>
                  Immerse yourself in the charm of Cabo San Lucas, enjoy the incredible beaches and vibrant urban life, known as one of the largest beach resorts in Mexico and Latin America. Experience thrilling adventures in a paradise setting during your next vacation to Mexico's southernmost tip.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6" data-aos="fade-up" data-aos-delay="400">
        <div class="card-item">
          <div class="row">
            <div class="col-xl-5">
              <div class="card-bg">
                <img
                  src="https://cabo-airport-shuttle.com/assets/img/destinations/los-cabos.webp"
                  alt="Los Cabos"
                />
              </div>
            </div>
            <div class="col-xl-7 d-flex align-items-center">
              <div class="card-body">
                <h4 class="card-title">Los Cabos</h4>
                <p>
                  Los Cabos is one of the municipalities located at the southernmost tip of Mexico, offering ideal conditions for beach activities, nightlife, fun, and relaxation. This destination is renowned worldwide for its stunning beauty and the wide variety of water activities you can enjoy within the city.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

        <section id="recent-blog-posts" class="recent-blog-posts section">
  <div class="container section-title" data-aos="fade-up">
    <h2>Why Choose Us?</h2>
    <p>Why choose our private transportation service in Baja Blue Transportation?</p>
    <p>
      24/7 Service: Transportation available anytime, tailored to your needs.  
      Bilingual Drivers: Professional drivers fluent in English and Spanish.  
      Flight Tracking: We ensure timely service, even if your flight is delayed.  
      Luxury Vehicles: Travel in style with our modern and well-maintained vehicles.
    </p>
  </div>

  <div class="container">
    <div class="row gy-5">
      <div class="col-xl-4 col-md-6">
        <div
          class="post-item position-relative h-100"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div class="post-img position-relative overflow-hidden">
            <img
              src={require("../../assets/img/Home/about-us1.jpg")}
              class="img-fluid"
              alt="Luxury transportation in Baja Blue Transportation"
            />
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-md-6">
        <div
          class="post-item position-relative h-100"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div class="post-img position-relative overflow-hidden">
            <img
              src={require("../../assets/img/Home/about-us.jpg")}
              class="img-fluid"
              alt="Reliable transportation service in Baja Blue Transportation"
            />
          </div>
        </div>
      </div>

      <div
        class="col-xl-4 col-md-6"
        data-aos="fade-up"
        data-aos-delay="300"
      >
        <div class="post-item position-relative h-100">
          <div class="post-img position-relative overflow-hidden">
            <img
              src={require("../../assets/img/Home/about-us2.jpg")}
              class="img-fluid"
              alt="Bilingual drivers and safe transportation"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


        {/*    <section id="services" class="services section light-background">
      
            
            <div class="container section-title" data-aos="fade-up">
              <h2>Services</h2>
              <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit</p>
            </div>
      
            <div class="container">
      
              <div class="row gy-4">
      
                <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
                  <div class="service-item  position-relative">
                    <div class="icon">
                      <i class="fa-solid fa-mountain-city"></i>
                    </div>
                    <h3>Nesciunt Mete</h3>
                    <p>Provident nihil minus qui consequatur non omnis maiores. Eos accusantium minus dolores iure perferendis tempore et consequatur.</p>
                    <a href="#" class="readmore stretched-link">Read more <i class="bi bi-arrow-right"></i></a>
                  </div>
                </div>
      
                <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                  <div class="service-item position-relative">
                    <div class="icon">
                      <i class="fa-solid fa-arrow-up-from-ground-water"></i>
                    </div>
                    <h3>Eosle Commodi</h3>
                    <p>Ut autem aut autem non a. Sint sint sit facilis nam iusto sint. Libero corrupti neque eum hic non ut nesciunt dolorem.</p>
                    <a href="#" class="readmore stretched-link">Read more <i class="bi bi-arrow-right"></i></a>
                  </div>
                </div>
      
                <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                  <div class="service-item position-relative">
                    <div class="icon">
                      <i class="fa-solid fa-compass-drafting"></i>
                    </div>
                    <h3>Ledo Markt</h3>
                    <p>Ut excepturi voluptatem nisi sed. Quidem fuga consequatur. Minus ea aut. Vel qui id voluptas adipisci eos earum corrupti.</p>
                    <a href="#" class="readmore stretched-link">Read more <i class="bi bi-arrow-right"></i></a>
                  </div>
                </div>
      
                <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                  <div class="service-item position-relative">
                    <div class="icon">
                      <i class="fa-solid fa-trowel-bricks"></i>
                    </div>
                    <h3>Asperiores Commodit</h3>
                    <p>Non et temporibus minus omnis sed dolor esse consequatur. Cupiditate sed error ea fuga sit provident adipisci neque.</p>
                    <a href="#" class="readmore stretched-link">Read more <i class="bi bi-arrow-right"></i></a>
                  </div>
                </div>
      
                <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
                  <div class="service-item position-relative">
                    <div class="icon">
                      <i class="fa-solid fa-helmet-safety"></i>
                    </div>
                    <h3>Velit Doloremque</h3>
                    <p>Cumque et suscipit saepe. Est maiores autem enim facilis ut aut ipsam corporis aut. Sed animi at autem alias eius labore.</p>
                    <a href="#" class="readmore stretched-link">Read more <i class="bi bi-arrow-right"></i></a>
                  </div>
                </div>
      
                <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
                  <div class="service-item position-relative">
                    <div class="icon">
                      <i class="fa-solid fa-arrow-up-from-ground-water"></i>
                    </div>
                    <h3>Dolori Architecto</h3>
                    <p>Hic molestias ea quibusdam eos. Fugiat enim doloremque aut neque non et debitis iure. Corrupti recusandae ducimus enim.</p>
                    <a href="#" class="readmore stretched-link">Read more <i class="bi bi-arrow-right"></i></a>
                  </div>
                </div>
      
              </div>
      
            </div>
      
          </section> */}

        {/* 
        <section id="features" class="features section">
          <div class="container">
            <ul
              class="nav nav-tabs row  g-2 d-flex"
              data-aos="fade-up"
              data-aos-delay="100"
              role="tablist"
            >
              <li class="nav-item col-3" role="presentation">
                <a
                  class="nav-link active show"
                  data-bs-toggle="tab"
                  data-bs-target="#features-tab-1"
                  aria-selected="true"
                  role="tab"
                >
                  <h4>Modisit</h4>
                </a>
              </li>

              <li class="nav-item col-3" role="presentation">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#features-tab-2"
                  aria-selected="false"
                  tabindex="-1"
                  role="tab"
                >
                  <h4>Praesenti</h4>
                </a>
              </li>
              <li class="nav-item col-3" role="presentation">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#features-tab-3"
                  aria-selected="false"
                  tabindex="-1"
                  role="tab"
                >
                  <h4>Explica</h4>
                </a>
              </li>

              <li class="nav-item col-3" role="presentation">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#features-tab-4"
                  aria-selected="false"
                  tabindex="-1"
                  role="tab"
                >
                  <h4>Nostrum</h4>
                </a>
              </li>
            </ul>

            <div class="tab-content" data-aos="fade-up" data-aos-delay="200">
              <div
                class="tab-pane fade active show"
                id="features-tab-1"
                role="tabpanel"
              >
                <div class="row">
                  <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                    <h3>Voluptatem dignissimos provident</h3>
                    <p class="fst-italic">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                    <ul>
                      <li>
                        <i class="bi bi-check2-all"></i>{" "}
                        <span>
                          Ullamco laboris nisi ut aliquip ex ea commodo
                          consequat.
                        </span>
                      </li>
                      <li>
                        <i class="bi bi-check2-all"></i>{" "}
                        <span>
                          Duis aute irure dolor in reprehenderit in voluptate
                          velit.
                        </span>
                      </li>
                      <li>
                        <i class="bi bi-check2-all"></i>{" "}
                        <span>
                          Ullamco laboris nisi ut aliquip ex ea commodo
                          consequat. Duis aute irure dolor in reprehenderit in
                          voluptate trideta storacalaperda mastiro dolore eu
                          fugiat nulla pariatur.
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div class="col-lg-6 order-1 order-lg-2 text-center">
                    <img
                      src={require("../../assets/img/features-1.jpg")}
                      alt=""
                      class="img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="features-tab-2" role="tabpanel">
                <div class="row">
                  <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                    <h3>Neque exercitationem debitis</h3>
                    <p class="fst-italic">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                    <ul>
                      <li>
                        <i class="bi bi-check2-all"></i>{" "}
                        <span>
                          Ullamco laboris nisi ut aliquip ex ea commodo
                          consequat.
                        </span>
                      </li>
                      <li>
                        <i class="bi bi-check2-all"></i>{" "}
                        <span>
                          Duis aute irure dolor in reprehenderit in voluptate
                          velit.
                        </span>
                      </li>
                      <li>
                        <i class="bi bi-check2-all"></i>{" "}
                        <span>
                          Provident mollitia neque rerum asperiores dolores quos
                          qui a. Ipsum neque dolor voluptate nisi sed.
                        </span>
                      </li>
                      <li>
                        <i class="bi bi-check2-all"></i>{" "}
                        <span>
                          Ullamco laboris nisi ut aliquip ex ea commodo
                          consequat. Duis aute irure dolor in reprehenderit in
                          voluptate trideta storacalaperda mastiro dolore eu
                          fugiat nulla pariatur.
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div class="col-lg-6 order-1 order-lg-2 text-center">
                    <img
                      src={require("../../assets/img/features-2.jpg")}
                      alt=""
                      class="img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="features-tab-3" role="tabpanel">
                <div class="row">
                  <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                    <h3>Voluptatibus commodi accusamu</h3>
                    <ul>
                      <li>
                        <i class="bi bi-check2-all"></i>{" "}
                        <span>
                          Ullamco laboris nisi ut aliquip ex ea commodo
                          consequat.
                        </span>
                      </li>
                      <li>
                        <i class="bi bi-check2-all"></i>{" "}
                        <span>
                          Duis aute irure dolor in reprehenderit in voluptate
                          velit.
                        </span>
                      </li>
                      <li>
                        <i class="bi bi-check2-all"></i>{" "}
                        <span>
                          Provident mollitia neque rerum asperiores dolores quos
                          qui a. Ipsum neque dolor voluptate nisi sed.
                        </span>
                      </li>
                    </ul>
                    <p class="fst-italic">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                  </div>
                  <div class="col-lg-6 order-1 order-lg-2 text-center">
                    <img
                      src={require("../../assets/img/features-3.jpg")}
                      alt=""
                      class="img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="features-tab-4" role="tabpanel">
                <div class="row">
                  <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                    <h3>Omnis fugiat ea explicabo sunt</h3>
                    <p class="fst-italic">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                    <ul>
                      <li>
                        <i class="bi bi-check2-all"></i>{" "}
                        <span>
                          Ullamco laboris nisi ut aliquip ex ea commodo
                          consequat.
                        </span>
                      </li>
                      <li>
                        <i class="bi bi-check2-all"></i>{" "}
                        <span>
                          Duis aute irure dolor in reprehenderit in voluptate
                          velit.
                        </span>
                      </li>
                      <li>
                        <i class="bi bi-check2-all"></i>{" "}
                        <span>
                          Ullamco laboris nisi ut aliquip ex ea commodo
                          consequat. Duis aute irure dolor in reprehenderit in
                          voluptate trideta storacalaperda mastiro dolore eu
                          fugiat nulla pariatur.
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div class="col-lg-6 order-1 order-lg-2 text-center">
                    <img
                      src={require("../../assets/img/features-4.jpg")}
                      alt=""
                      class="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section id="projects" class="projects section">
          <div class="container section-title" data-aos="fade-up">
            <h2>Our vehicles</h2>
          </div>

          <div class="container">
            
            <div
              class="isotope-layout"
              data-default-filter="*"
              data-layout="masonry"
              data-sort="original-order"
            >
           

              <div
                class="row gy-4 isotope-container"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-remodeling">
                  <div class="portfolio-content h-100">
                    <img
                      src={require("../../assets/img/Camionetas/auto-1.jpg")}
                      class="img-fluid"
                      alt=""
                    />
                  
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-construction">
                  <div class="portfolio-content h-100">
                    <img
                      src={require("../../assets/img/Trafies/camioneta-modal.jpg")}
                      class="img-fluid"
                      alt=""
                    />
                 
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-repairs">
                  <div class="portfolio-content h-100">
                    <img
                      src={require("../../assets/img/Home/auto-3.jpg")}
                      class="img-fluid"
                      alt=""
                    />
                    <div class="portfolio-info">
                      <a
                        href={require("../../assets/img/Home/auto-3.jpg")}
                        data-gallery="portfolio-gallery-branding"
                        class="glightbox preview-link"
                      >
                        <FiZoomIn class="bi bi-zoom-in" />
                      </a>
                      {/*   <a href="project-details.html" title="More Details" class="details-link"><i class="bi bi-link-45deg"></i></a> */}
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-design">
                  <div class="portfolio-content h-100">
                    <img
                      src={require("../../assets/img/Home/auto-4.jpg")}
                      class="img-fluid"
                      alt=""
                    />
                    <div class="portfolio-info">
                      <a
                        href={require("../../assets/img/Home/auto-4.jpg")}
                        data-gallery="portfolio-gallery-book"
                        class="glightbox preview-link"
                      >
                        <FiZoomIn class="bi bi-zoom-in" />
                      </a>
                      {/*            <a href="project-details.html" title="More Details" class="details-link"><i class="bi bi-link-45deg"></i></a> */}
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-remodeling">
                  <div class="portfolio-content h-100">
                    <img
                      src={require("../../assets/img/Home/auto-7.jpg")}
                      class="img-fluid"
                      alt=""
                    />
                    <div class="portfolio-info">
                      <a
                        href={require("../../assets/img/Home/auto-7.jpg")}
                        data-gallery="portfolio-gallery-app"
                        class="glightbox preview-link"
                      >
                        <FiZoomIn class="bi bi-zoom-in" />
                      </a>
                      {/*        <a href="project-details.html" title="More Details" class="details-link"><i class="bi bi-link-45deg"></i></a> */}
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-construction">
                  <div class="portfolio-content h-100">
                    <img
                      src={require("../../assets/img/Home/auto-8.jpg")}
                      class="img-fluid"
                      alt=""
                    />
                    <div class="portfolio-info">
                      <a
                        href={require("../../assets/img/Home/auto-8.jpg")}
                        data-gallery="portfolio-gallery-product"
                        class="glightbox preview-link"
                      >
                        <FiZoomIn class="bi bi-zoom-in" />
                      </a>
                      {/*   <a href="project-details.html" title="More Details" class="details-link"><i class="bi bi-link-45deg"></i></a> */}
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-repairs">
                  <div class="portfolio-content h-100">
                    <img
                      src={require("../../assets/img/Home/auto-11.jpg")}
                      class="img-fluid"
                      alt=""
                    />
                    <div class="portfolio-info">
                      <a
                        href={require("../../assets/img/Home/auto-11.jpg")}
                        data-gallery="portfolio-gallery-branding"
                        class="glightbox preview-link"
                      >
                        <FiZoomIn class="bi bi-zoom-in" />
                      </a>
                      <a
                        href="project-details.html"
                        title="More Details"
                        class="details-link"
                      >
                        <i class="bi bi-link-45deg"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-design">
                  <div class="portfolio-content h-100">
                    <img
                      src={require("../../assets/img/Home/auto-12.jpg")}
                      class="img-fluid"
                      alt=""
                    />
                    <div class="portfolio-info">
                      <a
                        href={require("../../assets/img/Home/auto-12.jpg")}
                        data-gallery="portfolio-gallery-book"
                        class="glightbox preview-link"
                      >
                        <FiZoomIn class="bi bi-zoom-in" />
                      </a>
                      {/*         <a href="project-details.html" title="More Details" class="details-link"><i class="bi bi-link-45deg"></i></a> */}
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-design">
                  <div class="portfolio-content h-100">
                    <img
                      src={require("../../assets/img/Home/auto-13.jpg")}
                      class="img-fluid"
                      alt=""
                    />
                    <div class="portfolio-info">
                      <a
                        href={require("../../assets/img/Home/auto-13.jpg")}
                        data-gallery="portfolio-gallery-book"
                        class="glightbox preview-link"
                      >
                        <FiZoomIn class="bi bi-zoom-in" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="services" class="services section light-background">
          <div class="container section-title" data-aos="fade-up">
            <p>We have availability in the following payment methods</p>
            <h2>
              The following payment methods are available: Cash, credit card,
              and PayPal. Select the option that best suits you and your needs.
            </h2>
          </div>

          <div class="container">
            <div class="row gy-4">
              <div
                class="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div class="service-card  position-relative">
                  <div class="icon">
                    <i class="fa-solid fa-mountain-city">
                      <FaCreditCard />
                    </i>
                  </div>
                  <h3>Credit / Debit Card</h3>
                </div>
              </div>
              <div
                class="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div class="service-card position-relative">
                  <div class="icon">
                    <i class="fa-solid fa-compass-drafting">
                      <FaIdCard />
                    </i>
                  </div>
                  <h3>Bank transfer</h3>
                </div>
              </div>

              <div
                class="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div class="service-card position-relative">
                  <div class="icon">
                    <i class="fa-solid fa-compass-drafting">
                      <IoLogoPaypal />
                    </i>
                  </div>
                  <h3>PayPal</h3>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <a
        href="#"
        id="scroll-top"
        class="scroll-top d-flex align-items-center justify-content-center"
      >
        <IoMdArrowUp class="icon-arrow-up" />
      </a>
    </div>
  );
}
