import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import BookingForm from '../components/BookingForm/BookingForm';
import Navbar from '../components/Navbar/Navbar';

// Define los nombres de los pasos
const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];

// Define los formularios que vas a mostrar en cada paso
const FormTuristic1 = () => (
  <div>
    <Typography variant="h6">Form 1: Campaign Settings</Typography>
    <input type="text" placeholder="Enter campaign name" />
  </div>
);

const FormTuristic2 = () => (
  <div>
    <Typography variant="h6">Form 2: Ad Group Settings</Typography>
    <input type="text" placeholder="Enter ad group name" />
  </div>
);

const FormTuristic3 = () => (
  <div>
    <Typography variant="h6">Form 3: Create Ad</Typography>
    <input type="text" placeholder="Enter ad details" />
  </div>
);

// Función principal
export default function Test() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => steps.length;
  const completedSteps = () => Object.keys(completed).length;
  const isLastStep = () => activeStep === totalSteps() - 1;
  const allStepsCompleted = () => completedSteps() === totalSteps();

  const handleNext = () => {
    const newActiveStep = isLastStep() && !allStepsCompleted()
      ? steps.findIndex((step, i) => !(i in completed))
      : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleStep = (step) => () => setActiveStep(step);

  const handleComplete = () => {
    setCompleted({ ...completed, [activeStep]: true });
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  // Renderiza el formulario adecuado basado en el paso activo
  const renderForm = () => {
    switch (activeStep) {
      case 0:
        return <FormTuristic1 />;
      case 1:
        return <FormTuristic2 />;
      case 2:
        return <FormTuristic3 />;
      default:
        return null;
    }
  };

  return (
<div>
  <div>

  <BookingForm/>
  </div>
</div>
  );
}
